import { BakeShadows, MeshReflectorMaterial, useGLTF } from '@react-three/drei';
import { Canvas, useFrame } from '@react-three/fiber';
import { Bloom, DepthOfField, EffectComposer } from '@react-three/postprocessing';
import { easing } from 'maath';
import styled from 'styled-components';
import Runway from './Runway';
const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: -999;
  //background: #000;
  @media (max-width: 600px) {
    .model {
      transform: scale(0.5);
    }
  }

  @media (min-width: 601px) and (max-width: 1200px) {
    .model {
      transform: scale(0.8);
    }
  }
`;
export default function RunwayModel({isDarkMode, redeemAudio}) {

  return (
    <Wrap>
    <Canvas shadows dpr={[1, 1.5]} camera={{ position: [-10.5, 1, 5.5], fov: 45, near: 1, far: 20 }} eventSource={document.getElementById('root')} eventPrefix="client">
      {/* Lights */}
      <color attach="background" args={[isDarkMode ? "black" : "white"]}/>
      <hemisphereLight intensity={0.15} groundColor={isDarkMode ? "black" : "white"}  />
      <spotLight position={[10, 20, 10]} angle={0.12} penumbra={1} intensity={1} castShadow shadow-mapSize={1024} />
      {/* Main scene */}
      <group position={[-0, -1, 0]}>
        {/* Auto-instanced sketchfab model */}
        
          <Runway redeemAudio={redeemAudio} isDarkMode={isDarkMode} scale={0.25} />

        {/* Plane reflections + distance blur */}
        <mesh receiveShadow rotation={[-Math.PI / 2, 0, 0]}>
          <planeGeometry args={[50, 50]} />
          <MeshReflectorMaterial
    blur={[100, 30]}
    resolution={2048}
    mixBlur={0.2}
    mixStrength={80}
    roughness={1}
    depthScale={1.2}
    minDepthThreshold={0.4}
    maxDepthThreshold={1.4}
    color="rgb(48, 57, 88)"
    metalness={0.8}
/>
        </mesh>
        {/* Bunny and a light give it more realism */}
        <pointLight distance={1.5} intensity={1} position={[-0.15, 0.7, 0]} color="orange" />
      </group>
      {/* Postprocessing */}
      <EffectComposer disableNormalPass>
        <Bloom luminanceThreshold={0} mipmapBlur luminanceSmoothing={0.0} intensity={isDarkMode ? 6 : 1}/>
        <DepthOfField target={[0, 0, 13]} focalLength={0.3} bokehScale={15} height={700} />
      </EffectComposer>
      {/* Camera movements */}
      <CameraRig />
      {/* Small helper that freezes the shadows for better performance */}
      <BakeShadows />
    </Canvas>
    </Wrap>
  )
}

function Bun(props) {
  const { nodes } = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/bunny/model.gltf')
  return (
    <mesh receiveShadow castShadow geometry={nodes.bunny.geometry} {...props}>
      <meshStandardMaterial color="#222" roughness={0.5} />
    </mesh>
  )
}

function CameraRig() {
  useFrame((state, delta) => {
    easing.damp3(state.camera.position, [ (state.pointer.x * state.viewport.width) / 4, (1 + state.pointer.y) / 2, 5.5], 0.5, delta)
    state.camera.lookAt(0, 0, 0)
  })
}

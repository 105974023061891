
import styled from 'styled-components';

//Futuristic Button

// Kave Button
export const KaveBtn = styled.button`
  cursor: pointer;
  position: relative;
  border: solid 1px ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 0.3)' : 'rgba(0, 0, 0, 0.3)'};
  background: none;
  color: ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 0.5)' : 'rgba(0, 0, 0, 0.5)'};
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1.8px;
  width: 200px;
  height: 70px;
  padding: 0;
  transition: all 0.25s ease;
  outline: none;

  &:before, &:after {
    content: "";
    position: absolute;
    width: 60px;
    height: 1px;
    background: ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 1)' : 'rgba(0, 0, 0, 1)'};
    transition: all 0.25s ease;
  }

  &:before {
    top: 0;
    left: 10%;
    transform: translateY(-1px);
  }

  &:after {
    bottom: 0;
    right: 10%;
    transform: translateY(1px);
  }

  &:hover {
    box-shadow: 1px 1px 8px ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 0.3)' : 'rgba(0, 0, 0, 0.3)'};
    color: ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 1)' : 'rgba(0, 0, 0, 1)'};
    text-shadow: 0 0 8px ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 0.4)' : 'rgba(0, 0, 0, 0.4)'};

    &:before {
      left: 0;
      width: 20px;
    }

    &:after {
      right: 0;
      width: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    min-width: 320px;
    height: 40px;
  }
`;

// Kave Line
export const KaveLine = styled.div`
  &:before {
    content: "";
    position: absolute;
    bottom: 30%;
    right: 0;
    width: 1px;
    height: 20px;
    transform: translateX(1px);
    background: rgba(256, 256, 256, 1);
    transition: all 0.25s ease;
  }

  &:after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 20px;
    transform: translateX(-1px);
    background: rgba(256, 256, 256, 1);
    transition: all 0.25s ease;
  }
`;



  
//
//
//
export const RedeemPageContainer = styled.div`

  height: 100vh;
  width: 100%;
 // margin-top: -80px; 
  // display: grid;
  //grid-template-rows: auto auto;
  z-index: -1;
  overflow: hidden; /* Hide content that overflows the container */
  overflow-y: auto; /* Allow vertical scrolling within the container if needed */
`;


export const RedeemPageHeader = styled.h1`
  
    width: 1100px;
    height: 44px;
    margin-top: 200px;
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);
  

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 29px;
    line-height: 43px;
    /* identical to box height, or 149% */

    align-items: center;
    text-align: center;

    color: #fff;
    @media screen and (max-width: 870px) {
       zoom: 0.9;
       
        }  
        @media screen and (max-width: 640px) {
            zoom: 0.8;
       
        } 
`

export const RedeemPageTopLine =styled.h2`
    margin-left: 50%;
    transform: translateX(-50%);

    width: 700px;
    height: 36px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 36px;
    /* identical to box height, or 189% */

    align-items: center;
    text-align: center;

    color: #F1F3FA;
    @media screen and (max-width: 870px) {
       zoom: 0.95;
       
        }  
        @media screen and (max-width: 640px) {
            zoom: 0.9;
       
        } 
`

export const BackButton = styled.button`
  position: absolute;
  left: 20%;
  cursor: pointer;
  border: solid 1px rgba(256, 256, 256, 0.3);
  background: none;
  color: rgba(256, 256, 256, 0.5);
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1.8px;
  width: 200px;
  height: 50px;
  padding: 0;
  transition: all 0.25s ease;
  outline: none;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(-1px);
    background: rgba(256, 256, 256, 1);
    transition: all 0.25s ease;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(1px);
    background: rgba(256, 256, 256, 1);
    transition: all 0.25s ease;
  }

  &:hover {
    box-shadow: 1px 1px 8px rgba(256, 256, 256, 0.3);
    color: rgba(256, 256, 256, 1);
    text-shadow: 0 0 8px rgba(256, 256, 256, 0.4);

    &:before {
      left: 0;
      width: 20px;
    }

    &:after {
      right: 0;
      width: 20px;
    }

    .kave-line:before {
      bottom: 0;
    }

    .kave-line:after {
      top: 0;
    }
  }

  @media screen and (max-width: 768px) {
 
    transform:scale(0.7);
    left: 5%;
}
`;

export const NextButton = styled.button`
position: absolute;
  right: 20%;
  cursor: pointer;
  border: solid 1px rgba(256, 256, 256, 0.3);
  background: none;
  color: rgba(256, 256, 256, 0.5);
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1.8px;
  width: 200px;
  height: 50px;
  padding: 0;
  transition: all 0.25s ease;
  outline: none;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(-1px);
    background: rgba(256, 256, 256, 1);
    transition: all 0.25s ease;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(1px);
    background: rgba(256, 256, 256, 1);
    transition: all 0.25s ease;
  }

  &:hover {
    box-shadow: 1px 1px 8px rgba(256, 256, 256, 0.3);
    color: rgba(256, 256, 256, 1);
    text-shadow: 0 0 8px rgba(256, 256, 256, 0.4);

    &:before {
      left: 0;
      width: 20px;
    }

    &:after {
      right: 0;
      width: 20px;
    }

    .kave-line:before {
      bottom: 0;
    }

    .kave-line:after {
      top: 0;
    }
  }
  @media screen and (max-width: 768px) {
    right: 5%;
 transform:scale(0.7);

}
`;

export const ButtonContainer = styled.div`
   
    height: 80px;
    width: 100vw;
    //box-shadow: 15px 10px 15px 0px rgba(0, 0, 0, 0.25), 0px 10px 15px 0px rgba(0, 0, 0, 0.25), 0px 10px 5px 5px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    display: grid;
    //background: #FFFFFF;
    position: fixed;
    padding: 0 50px;
   // overflow: hidden;
   // background:#fff;
   z-index: 9999999;
   
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 768px) {

 
      bottom: 50px;
}
    `;
const faqData = [
  {
    header: "REDEMPTION & SHIPPING",
    questions: [
      {
        question: "How do I redeem the Web 3 Outfitters Merch NFT?",
        answer: "To redeem your Web 3 Outfitters Merch NFT during the specified redemption period, please follow these steps:",
        bullets: [
          "Visit our designated redemption website announced on our official social media channels.",
          "Connect your crypto wallet to the website.",
          "Click on the 'Redeem' button.",
          "A small transaction fee or 'gas' will be required during the redemption process.",
          "Once your NFT has been redeemed, you will receive a new NFT and the corresponding physical merchandise.",
          "Note: Your original NFT will be burned (disappear) after redemption."
        ]
      },
      {
        question: "Is shipping included in the price of the Web 3 Outfitters Merch NFT?",
        answer: "No, shipping is not included in the price of the NFT. Shipping will be calculated and charged at the time of redemption based on your shipping address. Additional customs, taxes, or VAT may apply and will be calculated at checkout."
      },
      {
        question: "Where do you ship?",
        answer: "We ship worldwide except to countries listed on our DO NOT SHIP list, which includes certain countries due to sanctions or logistical challenges."
      }
    ]
  },
  {
    header: "GENERAL FAQS",
    questions: [
      {
        question: "What is the Web 3 Outfitters Merch NFT?",
        answer: "The Web 3 Outfitters Merch NFT is a unique, non-fungible token that is redeemable for exclusive physical merchandise from Web 3 Outfitters. It's a limited-edition collectible stored on the blockchain, ensuring authenticity and security."
      },
      {
        question: "How do I buy the Web 3 Outfitters Merch NFT?",
        answer: "You can purchase the NFT on OpenSea or other official platforms as announced. Ensure to use links provided by official channels for security."
      },
      {
        question: "Do I need a crypto wallet to buy the Web 3 Outfitters Merch NFT?",
        answer: "Yes, a crypto wallet is required for purchasing and holding the NFT. Popular wallet options include MetaMask, Coinbase Wallet, Trust Wallet, etc."
      },
      {
        question: "What crypto wallets can I use to purchase the NFT on the OpenSea marketplace?",
        answer: "Wallets compatible with the OpenSea marketplace include MetaMask, Coinbase Wallet, Bitkeep, Bitski, Ledger Live, Opera Touch, Trust Wallet, and WalletConnect."
      },
      {
        question: "If I purchase with a credit or debit card, do I still need a crypto wallet?",
        answer: "Yes, a crypto wallet is necessary to hold your NFT, regardless of your payment method."
      },
      {
        question: "How much does the Web 3 Outfitters Merch NFT cost?",
        answer: "The cost varies as it's available on the secondary market. Prices are set by resellers. Be cautious to purchase the unredeemed NFT if you wish to redeem for the physical merchandise."
      }
    ]
  },
  {
    header: "PURCHASE, MINT & WALLET SETUP",
    questions: [
      {
        question: "How do I buy the Web³ Outfitters Merch NFT?",
        answer: "To purchase the Web³ Outfitters Merch NFT, follow these steps:",
        bullets: [
          "The NFT will be available for purchase on OpenSea, a popular NFT marketplace.",
          "Visit the official sale link: [OpenSea Collection](https://opensea.io/collection/web3outfitters).",
          "Ensure to click links only from official channels such as our site or Web³ Outfitters' official communications.",
          "Once you have purchased the Web³ Outfitters NFT, you can redeem it for the corresponding physical merchandise during the specified redemption period."
        ]
      },
      {
        question: "Do I need a crypto wallet to buy the Web³ Outfitters Merch NFT?",
        answer: "Yes, a crypto wallet is necessary to purchase and store the Web³ Outfitters Merch NFT. Some of the most popular wallets include MetaMask, Coinbase Wallet, and Trust Wallet. To set up a wallet, create an account and download the wallet software. Fund your wallet with cryptocurrency purchased from an exchange like Coinbase or Binance. For detailed instructions, refer to [OpenSea's Wallet Setup Guide](https://opensea.io/learn/wallet-setup)."
      },
      {
        question: "Where can I buy the Web³ Outfitters NFT?",
        answer: "The Web³ Outfitters NFT is available on OpenSea: [Web³ Outfitters Collection on OpenSea](https://opensea.io/collection/web3outfitters). To purchase, create a crypto wallet, connect it to OpenSea, and complete the purchase process. Please note that availability is subject to market conditions as it's a limited-edition collectible."
      },
      {
        question: "What crypto wallets can I use to purchase the Web³ Outfitters Merch NFT on OpenSea?",
        answer: "Compatible wallets for purchasing on OpenSea include MetaMask, Coinbase Wallet, Bitkeep, Bitski, Ledger Live, Opera Touch, Trust Wallet, and WalletConnect."
      },
      {
        question: "If I purchase with a credit or debit card, do I still need a crypto wallet?",
        answer: "Yes, even if you purchase the NFT using a card, you'll need a crypto wallet to receive and manage your NFT. The NFT will be stored on the blockchain and can be accessed through your wallet."
      },
      {
        question: "How much does the Web³ Outfitters Merch NFT cost?",
        answer: "The price of the Web³ Outfitters Merch NFT varies as it is available on the secondary market. Prices are determined by resellers. Be cautious to purchase only the unredeemed NFT if you want to redeem it for physical merchandise."
      }
    ]
  }
];

export default faqData;

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useAnimations, useGLTF } from '@react-three/drei';
import React, { useEffect, useRef, useState } from 'react';

import { useThree } from '@react-three/fiber';
import * as THREE from 'three';

export default function Runway({
  isDarkMode,
  redeemAudio,
  ...props
}) {
  const group = useRef()
 
 const { nodes, materials, animations } = useGLTF('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/runway.glb')
  //const { nodes, materials, animations } = useGLTF('/runway.glb')

  const { actions } = useAnimations(animations, group)
  
 const [video] = useState(() => Object.assign(document.createElement('video'), { src: 'https://w3omedia.s3.us-east-2.amazonaws.com/w3o+videos/web3videoMusic.mp4', crossOrigin: 'Anonymous', loop: true, muted: redeemAudio }));
 // const [video] = useState(() => Object.assign(document.createElement('video'), { src: '/web3videoMusic.mp4', crossOrigin: 'Anonymous', loop: true, muted: redeemAudio }));
  useEffect(() => {
    const playVideo = () => {
      // Ensure the video is muted on the first play
      video.muted = true; 
      video.play().catch(e => console.error("Error playing video:", e));
      // Change the muted state based on redeemAudio after the first play
      video.muted = !redeemAudio;
      ['mousemove', 'click', 'keydown', 'touchstart', 'scroll'].forEach(event => {
        window.removeEventListener(event, playVideo);
      });
    };
  
    ['mousemove', 'click', 'keydown', 'touchstart', 'scroll'].forEach(event => {
      window.addEventListener(event, playVideo, { once: true });
    });
  
    return () => {
      ['mousemove', 'click', 'keydown', 'touchstart', 'scroll'].forEach(event => {
        window.removeEventListener(event, playVideo);
      });
    };
  }, [video, redeemAudio]);
  
  const { size } = useThree();
  const [scale, setScale] = useState(1);
  const [scale2, setScale2] = useState(1);
  const isMobile = window.innerWidth <= 600;

  useEffect(() => {
    const updateScale = () => {
      const width = size.width;
      let newScale = 1;

      if (width <= 600) {
        newScale =  0.61428571; // Adjust the scale value as needed for smaller screens
      } else if (width > 600 && width <= 1200) {
        newScale =1; // Adjust the scale value as needed for medium-sized screens
      }
      setScale2(newScale)
      setScale(newScale);
    };

    updateScale();

    window.addEventListener('resize', updateScale);

    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, [size?.width]);

  const [position, setPosition] = useState([0, -2.5, 0]);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width <= 600) {
        setPosition([-1.95, 3, 0]); // Adjust the position value as needed for smaller screens
      } else {
        setPosition([0, 0, 0]); // Default position value for larger screens
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Load the video texture
  //const videoTexture = useTexture('/web3video.mp4');

  // Create a material with the video texture
 // const videoMaterial = React.useMemo(() => new THREE.MeshBasicMaterial({ map: videoTexture }), [videoTexture]);
console.log('redeem in runway', redeemAudio)
  return (
    <group ref={group}  position={[1.2,0,0]} {...props} dispose={null}>
    <group position={position} scale={scale} >
      <group name="baby" position={[-4.5, 2.58, 3.84]} rotation={[Math.PI / 2, 0, 0]} scale={[0.25, 0.25, 0.25]}>
        <primitive object={nodes.root} />
      </group>
      <group position={[-0.6, 2.45, 1.46]}>
        <primitive object={nodes.spine} />
      </group>
      <group position={[-4.6, 3.04, -1.15]}>
        <primitive object={nodes.root_1} />
        <primitive object={nodes['MCH-torsoparent']} />
        <primitive object={nodes['MCH-hand_ikparentL']} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
        <primitive object={nodes['MCH-hand_ikparentR']} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
        <primitive object={nodes['MCH-foot_ikparentL']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
        <primitive object={nodes['MCH-foot_ikparentR']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
      </group>
      <group position={[0, 0.07, 0.02]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.01, 0.01, 0.01]}>
        <group rotation={[Math.PI / 2, 0, 0]} />
      </group>
      <group position={[-4.51, 3.07, 0.07]} scale={[1.25, 0.5, 1.5]} rotation={[0, 0, Math.PI]}>
        <mesh geometry={nodes.Cube001.geometry} material={materials['Material.002']} />
        <mesh geometry={nodes.Cube001_1.geometry} material={materials['Material.003']} />
        <mesh geometry={nodes.Cube001_2.geometry} material={materials['Material.008']} />
      </group>
      <mesh
  name={'ring'}
  geometry={nodes.Plane005.geometry}
  material={!isDarkMode ? new THREE.MeshStandardMaterial({ color: 'black',  matte: true }) : materials['Material.004']}
  position={[-4.51, 6.6, 0]}
  scale={1.36}
/>

      <mesh
      name = 'screen' 
        geometry={nodes.Plane001.geometry}
        material={materials['Material.001']}
        position={[-4.51, 6.6, 0]}
        scale={[1.36, -1.36, 1.36]}
      >
             <meshBasicMaterial>
        <videoTexture attach="map" args={[video]} colorSpace={THREE.SRGBColorSpace} />
      </meshBasicMaterial>
      </mesh>
        <group scale={isDarkMode ? 1 : 0}  name="roofing">
          <group position={[-4.51, 11.28, 9.53]} scale={[0.38, 0.27, 0.27]}>
          <group scale={isMobile ? 0 : 1} >
            <mesh geometry={nodes.Plane002_1.geometry} material={materials['Material.006']} />
            <mesh  geometry={nodes.Plane002_2.geometry} material={nodes.Plane002_2.material} />
          </group>
          </group>
        </group>
        <mesh
        geometry={nodes.Cube071.geometry}
        material={materials['Material.005']}
        position={[-13.29, 3.09, 5.43]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={0.49}
      />
      <mesh
        geometry={nodes.cloth_parent001.geometry}
        material={materials['Material.028']}
        position={[-4.52, 2.76, -1.15]}
        scale={1}
      />
        <group scale={isDarkMode ? 1 : 0} >
 
      <mesh
        geometry={nodes.Plane003.geometry}
        material={nodes.Plane003.material}
        position={[-4.51, 11.28, 9.53]}
        scale={[0.38, 0.27, 0.27]}
      />
      <mesh
        geometry={nodes.Plane004.geometry}
        material={nodes.Plane004.material}
        position={[-4.51, 11.28, 9.53]}
        scale={[0.38, 0.27, 0.27]}
      />
      </group>

      <mesh geometry={nodes.Cylinder_1.geometry} material={nodes.Cylinder_1.material} />
      <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      <group position={[0, 0, -3.33]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={nodes.Cylinder_1.material} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      </group>
      <group position={[0, 0, 3.2]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={nodes.Cylinder_1.material} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      </group>
      <group position={[0, 0, 6.41]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={nodes.Cylinder_1.material} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      </group>
      <group position={[0, 0, 9.61]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={nodes.Cylinder_1.material} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      </group>
      <group position={[0, 0, 12.81]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={nodes.Cylinder_1.material} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      </group>
      <group position={[0, 0, 16.01]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={nodes.Cylinder_1.material} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      </group>
      <group position={[0, 0, 19.22]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={nodes.Cylinder_1.material} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      </group>
      <group position={[3.2, 0, 0]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={nodes.Cylinder_1.material} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      </group>
      <group position={[3.2, 0, -3.33]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={nodes.Cylinder_1.material} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      </group>
      <group position={[3.2, 0, 3.2]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={nodes.Cylinder_1.material} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      </group>
      <group position={[3.2, 0, 6.41]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={nodes.Cylinder_1.material} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      </group>
      <group position={[3.2, 0, 9.61]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={nodes.Cylinder_1.material} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      </group>
      <group position={[3.2, 0, 12.81]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={nodes.Cylinder_1.material} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      </group>
      <group position={[3.2, 0, 16.01]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={nodes.Cylinder_1.material} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      </group>
      <group position={[3.2, 0, 19.22]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={nodes.Cylinder_1.material} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      </group>
      <mesh
        geometry={nodes.Girl_Body002.geometry}
        material={materials['Material.027']}
        position={[-4.52, 2.35, -1.13]}
      />
      </group>
    </group>
  )
}

useGLTF.preload('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/runway.glb')
//useGLTF.preload('/runway.glb')


import { a, useSpring } from "@react-spring/three";
import { useGLTF, useScroll } from '@react-three/drei';
import { useFrame } from "@react-three/fiber";
import React, { Suspense, useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { EnvDesktop } from "../../../components/Environments/Environments";
import './Model.css';
export default function W3oHomeCloset({setWhitelistPopup, setIsMouseVisible, orbitControlsRef, setButtonPopup, setIsFooterVisible, buttonPopup, setProgress, orbitControlsEnabled, openWhitelistLink, scale, props}) {
 // console.log("setButtonPopup prop:", setButtonPopup);
  //const [buttonPopup, setButtonPopup] = useState(false);
  //console.log("setButtonPopup prop:", setButtonPopup);
 // console.log("setButtonPopup prop:", props);
 console.log("setButtonPopup prop:", setButtonPopup);
 //const group = useRef();
 const scroll = useScroll()
 const web = useRef();
  const sphere = useRef()
  const light = useRef()
  const [mode, setMode] = useState(false)
  const [down, setDown] = useState(false)
  const [hovered, setHovered] = useState(false)
  const [down2, setDown2] = useState(false)
  const [hovered2, setHovered2] = useState(false)
  const width = window.innerWidth;
  const isMobile = width <= 600; // Check if the window width is 600 or less
  
  //const [buttonPopup, setButtonPopup] = useState(false);


  const scaledFactor = isMobile ? 2 : 1; // Scaling factor based on mobile view or default
  const positionShift = isMobile ? -5 : 0; // Scaling factor based on mobile view or default


  const positionDiscX = isMobile ? 6 : 0; // Scaling factor based on mobile view or default
  const positionDiscZ = isMobile ? 7 : 0; // Scaling factor based on mobile view or default
  const positionDiscY = isMobile ? -6 : 0; // Scaling factor based on mobile view or default

  const positionWhiteZ = isMobile ? 6 : 0; // Scaling factor based on mobile view or default
  const positionWhiteX = isMobile ? 3 : 0; // Scaling factor based on mobile view or default
  const positionWhiteY = isMobile ? -6 : 0; // Scaling factor based on mobile view or default
  
  useFrame((state, delta) => {
    // mixer.current.update(delta);
    // const offset = 1 - scroll.offset;
   
     if (scroll === 0 || scroll === 1) {
       // No spinning at the top or bottom
       // You don't need to do anything here to prevent rotation
     } 
     if (scroll === 1) {
       // Show the footer when scrolling down (original condition)
       const offsetThreshold = 1 - scroll;
       const dampingEffect = 1; // Adjust this value as needed
       const newIsFooterVisible = offsetThreshold <= dampingEffect;
       setIsFooterVisible(newIsFooterVisible);
       //console.log("isFooterVisible updated:", newIsFooterVisible); // Add this line
     }
   });
      // Callback function to update mouseBody opacity based on orbitControls interaction
      const handleControlsUpdate = () => {
        if (orbitControlsRef) {
          const isInteracting = orbitControlsRef;
         // isMouseVisible = isInteracting ? 0 : 1;
         if (isInteracting === 1){
          setIsMouseVisible(false);
         }
        }
      };
    
      // Use frame to attach the callback to the onUpdate event of OrbitControls
      useFrame(() => handleControlsUpdate());
  
  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
    
  }, [hovered,])
  useEffect(() => {
    document.body.style.cursor = hovered2 ? 'pointer' : 'auto'
    
  }, [hovered2,])

  
  const openDiscordLink = () => {
    window.open("https://discord.gg/qSk7XKdtHa", "_blank");
  };




  // Springs for color and overall looks, this is state-driven animation
  // React-spring is physics based and turns static props into animated values
  const [{ wobble, wobble2}] = useSpring(
    {
      wobble: down ? 1.05 : hovered ? 1.05 : 1,
      wobble2: down2 ? 1.05 : hovered2 ? 1.05 : 1,

      config: (n) => n === 'wobble' && hovered && { mass: 2, tension: 1000, friction: 10 },
      config2: (n) => n === 'wobble2' && hovered2 && { mass: 2, tension: 750, friction: 15 }
    },
    [mode, hovered, down, down2, hovered2]
  )

  useEffect(() => {
    const loadModel = () => {
      return new Promise((resolve, reject) => {
        const manager = new THREE.LoadingManager();
        manager.onProgress = (item, loaded, total) => {
          const progress = (loaded / total) * 100;
          setProgress(progress); // Set the progress here
        };
       

      });
    };

    loadModel().then(({ nodes, materials, animations }) => {
      // Do any additional logic with the loaded model here (if needed)
    });
  }, [setProgress]);
  const group = useRef()
  //const { nodes, materials, animations } = useGLTF('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/w3oFinalSampleCloset.glb');
  const { nodes, materials, animations } = useGLTF('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/w3oFinalSampleCloset.glb');

 // const { actions } = useAnimations(animations, group)
  return (
    <>
    <group scale = {scale} ref={group} {...props} dispose={null}>
        <group ref = {orbitControlsRef} scale={.14}>
      <mesh geometry={nodes.Cylinder004_Material_06936.geometry} material={materials['Material.092']} />
      <mesh geometry={nodes.Cylinder004_Material_06936_1.geometry} material={materials['Material.093']} />
      <mesh
        geometry={nodes.Cylinder004_Material_06936_2.geometry}
        material={nodes.Cylinder004_Material_06936_2.material}
      />
      <mesh geometry={nodes.Cylinder004_Material_06936_3.geometry} material={materials['Material.094']} />
      <mesh geometry={nodes.Cylinder004_Material_06936_4.geometry} material={materials['Material.095']} />
      <mesh geometry={nodes.Cylinder004_Material_06936_5.geometry} material={materials['Material.096']} />
      <mesh geometry={nodes.Cylinder004_Material_06936_6.geometry} material={materials['Material.097']} />
      <mesh geometry={nodes.Cylinder004_Material_06936_7.geometry} material={materials['Material.098']} />
      <mesh geometry={nodes.Cylinder004_Material_06935.geometry} material={materials['Material.086']} />
      <mesh
        geometry={nodes.Cylinder004_Material_06935_1.geometry}
        material={nodes.Cylinder004_Material_06935_1.material}
      />
      <mesh geometry={nodes.Cylinder004_Material_06935_2.geometry} material={materials['PaletteMaterial001.011']} />
      <mesh geometry={nodes.Cylinder004_Material_06935_3.geometry} material={materials['cupbord2.017']} />
      <mesh geometry={nodes.Cylinder004_Material_06935_4.geometry} material={materials['cupbord2.018']} />
      <mesh geometry={nodes.Cylinder004_Material_06935_5.geometry} material={materials['cupbord2.019']} />
      <mesh geometry={nodes.Cylinder004_Material_06935_6.geometry} material={materials['cupbord2.020']} />
      <group position={[-3.94, 0.98, 5.04]} rotation={[Math.PI, -0.86, Math.PI]} scale={[0.03, 0.03, 0.03]}>
        <mesh geometry={nodes.bag_bag_0004.geometry} material={materials['material.002']} />
        <mesh geometry={nodes.bag_bag_0004_1.geometry} material={materials['belt.002']} />
        <mesh geometry={nodes.bag_bag_0004_2.geometry} material={materials['metal.002']} />
      </group>
      <mesh geometry={nodes.Floor_Top001.geometry} material={materials['Material.071']} />
      <mesh geometry={nodes.Middle_Bag005.geometry} material={materials['map_BagWomen_01.006']} />
      <mesh geometry={nodes.Perfume005.geometry} material={materials['PaletteMaterial004.003']} />
      <group position={[0.39, 1.36, -3.59]} scale={[11.48, 11.48, 12.94]}>
        <mesh geometry={nodes.Plane249.geometry} material={materials['tshirtonhanger3.010']} />
        <mesh geometry={nodes.Plane249_1.geometry} material={materials['tshirtonhanger3.011']} />
      </group>
      <mesh geometry={nodes.Top_Bag005.geometry} material={materials['bag1.006']} />
      <mesh geometry={nodes.Chain002.geometry} material={nodes.Chain002.material} />
      <mesh geometry={nodes.Chain002_1.geometry} material={nodes.Chain002_1.material} />
      <mesh geometry={nodes.Chain002_2.geometry} material={materials['tsh3.008']} />
      <mesh geometry={nodes.Chain004.geometry} material={nodes.Chain004.material} />
      <mesh geometry={nodes.Chain004_1.geometry} material={materials['PaletteMaterial003.003']} />
      <mesh geometry={nodes.Stand_Top001.geometry} material={nodes.Stand_Top001.material} />
      <mesh geometry={nodes.Hat003.geometry} material={nodes.Hat003.material} />
      <mesh geometry={nodes.Glass_Balls_Hanger001.geometry} material={nodes.Glass_Balls_Hanger001.material} />
      <mesh geometry={nodes.GlassBalls003.geometry} material={nodes.GlassBalls003.material} />
      <mesh geometry={nodes.FloorBottom001.geometry} material={nodes.FloorBottom001.material} />
      <mesh geometry={nodes.Clothes_Rod001.geometry} material={nodes.Clothes_Rod001.material} />
      <mesh geometry={nodes.Chain012.geometry} material={nodes.Chain012.material} />
      <mesh geometry={nodes.Chain012_1.geometry} material={materials['blinn5SG.006']} />
      <mesh geometry={nodes.Chain012_2.geometry} material={materials['blinn1SG.008']} />
      <mesh geometry={nodes.Chain012_3.geometry} material={materials['PaletteMaterial005.003']} />
      <mesh geometry={nodes.Chain012_4.geometry} material={materials['PaletteMaterial006.003']} />
      <mesh geometry={nodes.Chain012_5.geometry} material={materials['blinn3SG.006']} />
      <mesh geometry={nodes.Chain012_6.geometry} material={materials['blinn2SG.008']} />
      <mesh geometry={nodes.Chain012_7.geometry} material={nodes.Chain012_7.material} />
      <mesh geometry={nodes.Stand001.geometry} material={nodes.Stand001.material} />
      <mesh geometry={nodes.Chain014.geometry} material={nodes.Chain014.material} />
      <mesh geometry={nodes.Chain014_1.geometry} material={nodes.Chain014_1.material} />
      <mesh geometry={nodes.Cylinder004_Material_06042.geometry} material={materials['default.006']} />
      <mesh
        geometry={nodes.Cylinder004_Material_06042_1.geometry}
        material={nodes.Cylinder004_Material_06042_1.material}
      />
      <mesh
        geometry={nodes.Cylinder004_Material_06042_2.geometry}
        material={nodes.Cylinder004_Material_06042_2.material}
      />


              {/* {discord} */}
       
              <Suspense fallback={null}>
                    <a.group
            
                    scale={wobble}
                    onPointerOver={() => setHovered(true)}
                    onPointerOut={() => setHovered(false)}
                    onPointerDown={() => setDown(true)}
                    onClick={openDiscordLink}
                    className="cursor-pointer" 
                    >

<mesh geometry={nodes.Discord003.geometry} material={materials['Material.072']} />
                    </a.group>
                 </Suspense>

        {/* {whitelist} */}
        <Suspense fallback={null}>
            <a.group
            
                scale={wobble2}
                onPointerOver={() => setHovered2(true)}
                onPointerOut={() => setHovered2(false)}
                onPointerDown={() => setDown2(true)}
                onClick={() => {setWhitelistPopup(true)}}
               //onClick={() => props.setTrigger(false)} 

                className="cursor-pointer" 
            >
                <group            >
        
      <mesh geometry={nodes.Cube011.geometry} material={materials['Material.073']} />
      <mesh geometry={nodes.Cube011_1.geometry} material={materials['Material.074']} />
               </group>
            </a.group>
        </Suspense>
      
      <mesh geometry={nodes.Plane379.geometry} material={materials['cupbord2.012']} />
      <mesh geometry={nodes.Plane379_1.geometry} material={materials['Material.075']} />
      <mesh geometry={nodes.Plane379_2.geometry} material={materials['PaletteMaterial001.009']} />
      <mesh geometry={nodes.Plane379_3.geometry} material={materials['Material.076']} />
      <mesh geometry={nodes.Plane379_4.geometry} material={nodes.Plane379_4.material} />
      <mesh geometry={nodes.Plane379_5.geometry} material={materials['PaletteMaterial001.010']} />
      <mesh geometry={nodes.Plane379_6.geometry} material={materials['Material.077']} />
    </group>

    
    </group>
    <EnvDesktop orbitControlsEnabled={orbitControlsEnabled}/>
    </>
  )
}

useGLTF.preload('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/w3oFinalSampleCloset.glb')




import React, { useRef, useState } from 'react';
import CompleteRedeem from './RedeemNFT/CompleteRedeem';
import IntroSection from './RedeemNFT/IntroSection';
import ItemRedeem from './RedeemNFT/ItemRedeem';
import ThankYou from './RedeemNFT/ThankYou';
//import Error from './RedeemNFT/Error';

import RunwayModel from './RedeemBackground/RunwayModel';
import FAQAccordion from './RedeemNFT/FAQAccordion';
import { BackButton, ButtonContainer, NextButton, RedeemPageContainer } from './RedeemPageElements';
import Confirmation from './RedeemPayment/Confrimation';
import Information from './RedeemPayment/Information.js';
import Payment from './RedeemPayment/Payment';
import Shipping from './RedeemPayment/Shipping';
function RedeemPage({isDarkMode, redeemAudio,}) {

  const [state, setState] = useState('');
  const [error, setError] = useState('');
  const [step, setStep] = useState(1);
//FAQ Display
  const [showFAQ, setShowFAQ] = useState(false);

    
  
// useState for Date

  const [date, setDate] = useState();


 

  //Handle change to next step
  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };
  
  //Handle change to previous step 
  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };



  const handleInput = (e) => {
    const { name, value} = e.target;
    //e.preventDefault();
    // Handling checkboxes

      setState({ ...state, [name]: value }); // Handling other input types
    
  };
  
  
  const form = useRef();


/*
  const handlePropertyChange = (index) => (propertyData) => {
    setFormData((prevData) => {
      const updatedLocations = [...prevData.step3.locations];
      updatedLocations[index] = propertyData;
      
      return {
        ...prevData,
        step3: {
          ...prevData.step3,
          locations: updatedLocations,
        },
      };
    });
  };
  */

  // Get the component corresponding to the current step
  // Each component receives the following props:
  const getStepComponent = () => {
    switch (step) {
      case 1:
        return (
          <IntroSection
          isDarkMode={isDarkMode} 
               setShowFAQ={setShowFAQ}
            showFAQ={showFAQ}
            nextStep={nextStep}
            handleInput={handleInput}
            state={state}
          />
        );
      case 2:
        return (
          <ItemRedeem
          isDarkMode={isDarkMode} 
            nextStep={nextStep}
            prevStep={prevStep}
            handleInput={handleInput}
            state={state}
           
          />
        );
        case 3:
          return (
            <CompleteRedeem
            isDarkMode={isDarkMode} 
            nextStep={nextStep}
            prevStep={prevStep}
            handleInput={handleInput}
            state={state}
  //handlePropertyChange={handlePropertyChange} // Pass the function
        
          />
          );
      case 4:
        return (
          <ThankYou
          isDarkMode={isDarkMode} 
          nextStep={nextStep}
          prevStep={prevStep}
          handleInput={handleInput}
          state={state}
//handlePropertyChange={handlePropertyChange} // Pass the function
      
        />
        );
      case 5:
        return (
          <Information
          isDarkMode={isDarkMode} 
            nextStep={nextStep}
            prevStep={prevStep}
            state={state}
            handleInput={handleInput}
      
          />
        );
      case 6:
        return (
          <Shipping
          isDarkMode={isDarkMode} 
            nextStep={nextStep}
            prevStep={prevStep}
            state={state}
            handleInput={handleInput}
    
          
          />
        );
      case 7:
        return (
          <Payment
          isDarkMode={isDarkMode} 
            nextStep={nextStep}
            prevStep={prevStep}
            state={state}
            handleInput={handleInput}
            date={date}
            setDate={setDate}
          />
        );
      case 8:
        return (
          <Confirmation
          isDarkMode={isDarkMode} 
            nextStep={nextStep}
            prevStep={prevStep}
            state={state}
            handleInput={handleInput}
          />
        );
      default:
        console.log('This is a multi-step form built with React.');
        return null;
    }
  };

  return (
    <>
  <RedeemPageContainer>
  {showFAQ && <FAQAccordion setShowFAQ={setShowFAQ} showFAQ={showFAQ} isDarkMode={isDarkMode} />}
    <RunwayModel redeemAudio={redeemAudio} isDarkMode={isDarkMode} />
      {step === 1 && (
        <>
          <IntroSection
          isDarkMode={isDarkMode} 
            setShowFAQ={setShowFAQ}
            showFAQ={showFAQ}
            nextStep={nextStep}
            handleInput={handleInput}
            state={state}
      
          />
          <ButtonContainer>
            <NextButton onClick={nextStep}>NEXT</NextButton>
          </ButtonContainer>
        </>
      )}

      {step === 8 && (
        <>
          <Confirmation
          isDarkMode={isDarkMode} 
            nextStep={nextStep}
            prevStep={prevStep}
            state={state}
            handleInput={handleInput}
     
          />
          <ButtonContainer>
            <BackButton onClick={prevStep}>BACK</BackButton>
          </ButtonContainer>
        </>
      )}

      {step !== 1 && step !== 8 && (
        <>
          {getStepComponent()}
          <ButtonContainer>
            <NextButton onClick={nextStep}>NEXT</NextButton>
            <BackButton onClick={prevStep}>BACK</BackButton>
          </ButtonContainer>
        </>
      )}
      </RedeemPageContainer>
    </>
  );
}

export default RedeemPage;


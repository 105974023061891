import { useFrame } from '@react-three/fiber';
import { motion } from 'framer-motion';
import { easing } from 'maath';
import React, { useEffect, useRef, useState } from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { useSpring } from 'react-spring';
import icon from '../../../images/w3oNameHorzBlack.png';
import icon2 from '../../../images/w3oNameHorzWhite.png';
//import "./Navbar.css";
//import "./Navbar.scss";
//import coinbaseWalletModule from '@web3-onboard/coinbase';
//import injectedModule from '@web3-onboard/injected-wallets';
//import walletConnectModule from '@web3-onboard/walletconnect';
import bg2 from '../../../images/w3oDayBg.svg';
import bg from '../../../images/w3oNightBg.svg';
import {
  Bar,
  BarsContainer,
  BurgerContainer,
  BurgerStrip,
  ButtonText2,
  Closet,
  ClosetIcon2,
  ClosetWalletButton,
  IconsContainer,
  LightingWrap,
  MoonWrapper,
  Nav,
  NavItem,
  NavLinksContainer,
  NavLogo, NavLogoCont,
  NavMenu,
  PopUpBox,
  PopUpText,
  PopUpText2,
  SidebarHide,
  SunWrapper,
  TextSpan2,
  Wallet, WalletIcon
} from './RedeemNavbarElements';


//const audio = new Audio(require("../../audio/W3Oday.mp3").default);
//const MAINNET_RPC_URL = "https://goerli.infura.io/v3/7c9d1c18f6244605b8d57b8dd612aa7e";

//const injected = injectedModule();
//const walletConnect = walletConnectModule()
//const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: false })
/*
const onboard = Onboard({
  wallets: [
      injected,
      walletConnect,
      coinbaseWalletSdk
  ],
  chains: [
    {
      id: '0x5',
      token: 'gETH',
      label: 'Goerli Testnet',
      rpcUrl: MAINNET_RPC_URL
    }
  ]
});

*/



function CameraRig({ children }) {
  const group = useRef()
 // const snap = useSnapshot()
  useFrame((state, delta) => {
    easing.damp3(state.camera.position, [0 ? -state.viewport.width / 4 : 0, 0, 2], 0.25, delta)
    easing.dampE(group.current.rotation, [-state.pointer.y / 3, state.pointer.x / 2, 0], 0.1, delta)
  })
  return <group ref={group}>{children}</group>
}

function Sphere() {
  return (
    <mesh>
      <sphereBufferGeometry args={[0.5, 32, 32]} />
      <meshBasicMaterial color={0x00ff00} />
    </mesh>
  );
}




function Lighting({ onClick, isDarkMode }) {
  return (
    <>
      {isDarkMode ? (
        <MoonWrapper onClick={onClick}>
          <FaMoon />
        </MoonWrapper>
      ) : (
        <SunWrapper onClick={onClick}>
          <FaSun />
        </SunWrapper>
      )}
    </>
  );
}


function NavLogoContainer({ onClick, isDarkMode }) {
  return (
    <>
      {isDarkMode ? (
        <NavLogo to ='/Explore' src={icon2} alt="Icon"/>
                              
      ) : (
        <NavLogo to ='/Explore' src={icon} alt="Icon"  />
                              
      )}
    </>
  );
}
function RedeemNavbar({isOpen, redeemAudio, toggleMusic, isUtilitiesOpen, toggleDarkMode, isFooterVisible, toggle, started, isDarkMode, orbitControlsEnabled, toggleOrbitControls, setButtonPopup, setButtonPopup2, setButtonPopup3, buttonPopup, buttonPopup2, buttonPopup3}) {
    const [showNav, setShowNav] = useState(false);
    const [walletAddress, setWalletAddress] = useState("");
    const barPositions = [1, 5, 9, 13, 17, 21, 25, 29, 33, 37];
    const animationDurations = [474, 433, 407, 458, 400, 427, 441, 419, 487, 442];
    //const audio = new Audio(require("../../audio/W3Oday.mp3").default);
    const [mousePosition, setMousePosition] = useState([0, 0]);
    const [perfSucks, degrade] = useState(false)

    const [isWalletHovered, setIsWalletHovered] = useState(false);
    const [isClosetHovered, setIsClosetHovered] = useState(false);
  const [daoProgress, setDaoProgress] = useState(0);



  
    const handleWalletHover = () => {
      setIsWalletHovered(true);
    };
    const handleWalletMouseLeave = () => {
      setIsWalletHovered(false);
    };
  
    const handleClosetMouseLeave = () => {
      setIsClosetHovered(false);
    };
    const handleClosetHover = () => {
      setIsClosetHovered(true);
    };
    const opacityValue = orbitControlsEnabled ? 0 : 1;
    const [isHovered, setIsHovered] = useState(false);
    const checkboxRef = useRef(null);
    const pageTransition = useSpring({
      from: { scale: 0, opacity: 0 },
      to: { scale: 1, opacity: 1 },
    });
  
  
    const handleLightingWrapClick = () => {
      if (checkboxRef.current) {
        checkboxRef.current.click();
      }
    };
    const handleClick = () => {
      // Toggle the state when the burger is clicked
      setIsHovered(!isHovered);
    };
    useEffect(() => {
      const handleMouseMove = (event) => {
        const { clientX, clientY } = event;
        setMousePosition([clientX, clientY]);
      };
  
      window.addEventListener('mousemove', handleMouseMove);
  
      return () => {
        // Remove the event listener when the component unmounts
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }, []);

    // Event handler for toggling music play/pause
    
    /*
    const handleMusicToggle = () => {
      const audioPlayer = document.getElementById('audio-player');
  
      if (audioPlayer.paused) {
        audioPlayer.play();
      } else {
        audioPlayer.pause();
      }
      setIsMusicPlaying(audioPlayer.paused);
    };*/
    /*
    async function loadWeb3() {
        try {
            const wallets = await onboard.connectWallet();
            let web3 = window.web3 = new Web3(wallets[0].provider);
            const account = wallets[0].accounts[0].address;
            console.log("account", account)
            const msgToSign = web3.utils.sha3("Wallet Verification Message for Web3 Outfitters.");
            const signature = await web3.eth.personal.sign(msgToSign, account);
            const signingAddress = web3.eth.accounts.recover(msgToSign, 
            signature);
            console.log("signingAddress", signingAddress)
            if (account.toLowerCase() === signingAddress.toLowerCase()) {
                window.account = account;
                setWalletAddress(account);
            }
            return [(account.toLowerCase() === signingAddress.toLowerCase()), account];
        }
        catch (e) {
            console.log(e);
            return [false, ""];
        }
    }

    var user = {
      name: walletAddress,
      avatar: 'https://www.w3schools.com/howto/img_avatar.png',
      bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      email: 'test@example.com'
    }
    */
    // const user = useContext(UserContext);
   // console.log(user);


 
    return(
      <>
        {(!orbitControlsEnabled) && (
        
        <Nav
          style={{ opacity: opacityValue }}
          isUtilitiesOpen={isUtilitiesOpen} 
          initial={{ opacity: 0}}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0}}
          transition={{ duration: 0.2 }}
   
        >
          {/* Your animated elements */}
          <motion.div
          initial={{ opacity: 0}}
            animate={{ opacity: 1}}
            exit={{ opacity: 1}}
          >
           
             <SidebarHide  isUtilitiesOpen={isUtilitiesOpen}  isOpen={isOpen}>
             <NavLogoCont  to={'/'}    isUtilitiesOpen={isUtilitiesOpen}   isDarkMode={isDarkMode}>
                        {/*<NavIcon src = {symbol} />*/}
                                                        <NavLogoContainer   isDarkMode={isDarkMode} />
                                    
                                {/*<NavLogoTextWrap onCLick={toggleOrbitControls} > <NavLogoText >EXPLORE    CLOSETS </NavLogoText><ExploreCloset onCLick={toggleOrbitControls}  /></NavLogoTextWrap>*/}
                            </NavLogoCont>
                <NavMenu >
   {/*                    <ToggleBar isDarkMode={isDarkMode}>
                      <NavbarSwitchContainer isDarkMode={isDarkMode}>
                        <Switch    isDarkMode={isDarkMode} flat>
                          <SwitchInput type="checkbox" />
                          <SwitchLabel  isDarkMode={isDarkMode} ></SwitchLabel>
                          <ToggleCircle
                            isDarkMode={isDarkMode}
                            style={{
                              width: `35px`,
                              height: `35px`,
                              backgroundRepeat: "no-repeat",
                              backgroundImage: `url(${symbol})`,
                              top: `4px`,
                            }}
                            className="switch-handle"
                          />
                        </Switch>
                      </NavbarSwitchContainer>
                    </ToggleBar>


*/}



                
                        {/*

//Music Toggle
                          <NavItem>
                              <BarsContainer onClick={handleMusicToggle} isMusicPlaying={isMusicPlaying}>
                                        {barPositions.map((position, index) => (
                                          <Bar isDarkMode={isDarkMode} key={index} style={{ left: `${position}px`, animationDuration: `${animationDurations[index]}ms` }} />
                                        ))}
                              
                                    <audio id="audio-player" loop>
                                      <source src={isDarkMode ? audioDay :  audioNight} type="audio/mp3" />
                                  
                                    </audio>
                                    </BarsContainer>
                          </NavItem> 






//Night & Day Mode
                        <LightingWrap >
                        
                            <Checkbox isDarkMode={isDarkMode} type="checkbox" id="checkbox" onChange={toggleDarkMode} ref={checkboxRef} />
                            <CheckboxLabel isDarkMode={isDarkMode} htmlFor="checkbox">
                          
                              <Ball isDarkMode={isDarkMode} />
                              <ToggleLabels isDarkMode={isDarkMode}>
                              <SunWrapper>
                                <FaSun/>
                                </SunWrapper>
                                <MoonWrapper>
                                <FaMoon/>
                                </MoonWrapper>
                              </ToggleLabels>
                            </CheckboxLabel>
                        </LightingWrap>
                        */}
                 
             
                         
                         
                            
     
         
                
                        {  /* <InspectModelWrap>
                       
                                 <ButtonText>
                                  <TextSpan2  isDarkMode={isDarkMode}>Inspect Model</TextSpan2> 
                                  <InspectModel onClick={toggleOrbitControls} isDarkMode={isDarkMode} />
                                  </ButtonText>
                  
                            </InspectModelWrap>
                            */}
                            <NavItem>
                              <BarsContainer onClick={toggleMusic} redeemAudio={!redeemAudio}>
                                        {barPositions.map((position, index) => (
                                          <Bar isDarkMode={isDarkMode} key={index} style={{ left: `${position}px`, animationDuration: `${animationDurations[index]}ms` }} />
                                        ))}
                              
                                
                                    </BarsContainer>
                          </NavItem> 
                   </NavMenu>
              </SidebarHide>
                    
              <NavLinksContainer  >

                <SidebarHide isOpen={isOpen}>
        
                <ClosetWalletButton isDarkMode={isDarkMode}>
              
                                <IconsContainer>
                               
                                  <Wallet      onMouseEnter={handleWalletHover}
                                  onMouseLeave={handleWalletMouseLeave} 
                                  
                                  onClick={() => {setButtonPopup3(true); setButtonPopup2(false);  setButtonPopup(false); }} isDarkMode={isDarkMode}>
                                   
                                          <PopUpText>
                                 
                                <ButtonText2>
                                  <TextSpan2 isDarkMode={isDarkMode}>Connect Wallet    
                                    <WalletIcon   isDarkMode={isDarkMode}  />
                                  </TextSpan2>
                                </ButtonText2>
                     
</PopUpText>
                              </Wallet>
                              
                           
                                   <Closet    onMouseEnter={handleClosetHover}
                                              onMouseLeave={handleClosetMouseLeave}  onClick={() => { setButtonPopup2(true); setButtonPopup3(false); setButtonPopup(false); }} isDarkMode={isDarkMode}>
                                                       <PopUpText>
                                 
                                   <ButtonText2>
                                        <ClosetIcon2  onClick={() => {setButtonPopup2(true); setButtonPopup3(false);  setButtonPopup(false);}}  isDarkMode={isDarkMode}  />
                                        </ButtonText2>
                               



                                        </PopUpText>      
                          </Closet>

                                </IconsContainer>
     
                               
                              </ClosetWalletButton>

                              {isWalletHovered && (
                                <PopUpBox animate={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0 }} style={{ transform: `translateX(-50px)`, position: "relative",  left: "-150px", backgroundImage: `url(${isDarkMode ? bg2 : bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}  isDarkMode={isDarkMode}>
                                

                                <PopUpText2  transition={{delay: 0.25}} animate={{ opacity: 1}} initial={{ opacity: 0}}>COMING SOON</PopUpText2>
                                  </PopUpBox>
                                   )}


                                      {isClosetHovered && (

                                          <PopUpBox animate={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0 }} style={{ backgroundImage: `url(${isDarkMode ? bg2 : bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}  isDarkMode={isDarkMode}>
                                

                                <PopUpText2  transition={{delay: 0.25}} animate={{ opacity: 1}} initial={{ opacity: 0}}>COMING SOON</PopUpText2>
                                  </PopUpBox>
                                        )}
                           
                              
              


                
                {/*
                    <NavbarRightIconWrap>
                        
                                           <ModelContainer >
        <Canvas>
          <Suspense fallback={null}>

          <CameraRig>
          
            <ModernCloset mousePosition={mousePosition} />
            <AccumulativeShadows frames={100} alphaTest={0.85} opacity={0.8} color="red" scale={20} position={[0, -0.005, 0]}>
          <RandomizedLight amount={8} radius={6} ambient={0.5} intensity={1} position={[-1.5, 2.5, -2.5]} bias={0.001} />
        </AccumulativeShadows>
        <Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr" />
            <EffectComposer>7
            <Bloom luminanceThreshold={0.75} luminanceSmoothing={0.5} intensity={10} levels={4} mipmapBlur />
          </EffectComposer>
            </CameraRig>
                   
            <ambientLight  intensity={isDarkMode ? 1.5 : 0.5} />

<directionalLight
  intensity={1}
  position={[5, 5, 5]} // You can adjust the position of the directional light
  color="white" // You can set the light color
  castShadow // Enable shadow casting
/>

</Suspense>

</Canvas>
</ModelContainer>

            <Closet>
                            <PopUpText>
                               
                                      <NavLinks     onClick={() => {setButtonPopup(true); setButtonPopup3(false);  setButtonPopup2(false); }} isDarkMode={isDarkMode}  smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>  
                                        <ButtonText>
                                          <TextSpan isDarkMode={isDarkMode}>Your Closet</TextSpan>  
                                          <ClosetIcon2 />
                                        </ButtonText> 
                                      </NavLinks>
                                
                                  <PopUpBox>
            <p>COMING SOON 👀</p>
          </PopUpBox>
        </PopUpText>
                              </Closet>
                              <Wallet>
                                  <PopUpText>
                                      <NavLinks  onClick={() => {setButtonPopup(true); setButtonPopup3(false);  setButtonPopup2(false); }}  isDarkMode={isDarkMode} /*state = {user} *//*  onClick={loadWeb3}  smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>   
                                      <ButtonText>
                                            <TextSpan  isDarkMode={isDarkMode} >Your Wallet</TextSpan>  
                                            <WalletIcon />
                                          </ButtonText> 
                                      </NavLinks>
                                  <PopUpBox>
                                    <p>COMING SOON 👀</p>
                                  </PopUpBox>
                                </PopUpText>
                              </Wallet>
                        
                              
                              </NavbarRightIconWrap>
                              */}
                              
                             
                  
                          </SidebarHide>

                          <LightingWrap onClick={toggleDarkMode}  isDarkMode={isDarkMode} >
                        
                        <Lighting  isDarkMode={isDarkMode}/>
                     </LightingWrap>
            
                        <BurgerContainer 
                            isUtilitiesOpen={isUtilitiesOpen}
                            onClick={toggle}
                            isFooterVisible={isFooterVisible}
                            isDarkMode={isDarkMode} 
                             //toggle={toggle}
                             isHovered={isHovered}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                          >
                            <BurgerStrip isDarkMode={isDarkMode}  isHovered={isHovered} isOpen={isOpen}>
                              <div></div>
                              <div></div>
                              <div></div>
                            </BurgerStrip>
                          </BurgerContainer>
                       </NavLinksContainer>
     
            </motion.div>
            </Nav>
            )}
            </>
    )
}

export default RedeemNavbar;



/*
<NavItem >
<NavLinks onClick={loadWeb3} to ='/User' state = {user} smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>Connect Wallet</NavLinks>
</NavItem>

<NavItem >
<NavLinks  to ='/User' smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>Your Collection</NavLinks>
</NavItem> 



//COMING SOON animation 
                                         <ComingSoonCont>
                                    <LogoName  transition={  { delay: 0.5}}
                             initial ={ { scale: 0, opacity: 0, rotateX: -360, y:10000  }} // Start with flip
                            animate = {{ scale: 1.02, opacity: 1, rotateX: 0, y:0 }} // End with no flip
                            final = {{ scale: 1, opacity: 1, rotateX: 0 }} >     <ComingSoon           style={{
           
            backgroundImage: `url(${isDarkMode ? logo3 : logo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}/></LogoName>
                                   
                                  <LogoName2   transition={  { delay: 1}}
                            initial ={ { scale: 0, opacity: 0, rotateX: 180, y: -100 }} // Start with flip
                            animate = {{ scale: 1.02, opacity: 1, rotateX: 0, y: 0}} // End with no flip
                            final = {{ scale: 1, opacity: 1, rotateX: 0 }} >      <ComingSoon           style={{
                                       
                                          backgroundImage: `url(${isDarkMode ? logo4 : logo2})`,
                                          backgroundRepeat: 'no-repeat',
                                          backgroundSize: 'cover',
                                        }}/></LogoName2>
                                  
                                    </ComingSoonCont>
           <ComingSoonCont>
                                    <LogoName  transition={  { delay: 0.5}}
                             initial ={ { scale: 0, opacity: 0, rotateX: -360, y:10000  }} // Start with flip
                            animate = {{ scale: 1.02, opacity: 1, rotateX: 0, y:0 }} // End with no flip
                            final = {{ scale: 1, opacity: 1, rotateX: 0 }} >     <ComingSoon           style={{
           
            backgroundImage: `url(${isDarkMode ? logo3 : logo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}/></LogoName>
                                   
                                  <LogoName2   transition={  { delay: 1}}
                            initial ={ { scale: 0, opacity: 0, rotateX: 180, y: -100 }} // Start with flip
                            animate = {{ scale: 1.02, opacity: 1, rotateX: 0, y: 0}} // End with no flip
                            final = {{ scale: 1, opacity: 1, rotateX: 0 }} >      <ComingSoon           style={{
                                       
                                          backgroundImage: `url(${isDarkMode ? logo4 : logo2})`,
                                          backgroundRepeat: 'no-repeat',
                                          backgroundSize: 'cover',
                                        }}/></LogoName2>
                                  
                                    </ComingSoonCont>



*/



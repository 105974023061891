import { OrbitControls, PerformanceMonitor, Sparkles } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as THREE from 'three';
//import Overlay from '../../components/Overlay/Overlay';
//import Overlay from "../JoinDao/Overlay";
import W3oHomeCloset from './MainModels/W3oFinalSampleCloset';
const innerMaterial = new THREE.MeshStandardMaterial({
  transparent: true,
  opacity: 1,
  color: 'black',
  roughness: 0,
  side: THREE.FrontSide,
  blending: THREE.AdditiveBlending,
  polygonOffset: true,
  polygonOffsetFactor: 1,
  envMapIntensity: 2
});

const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  //background: #000;
  @media (max-width: 600px) {
    .model {
      transform: scale(0.5);
    }
  }

  @media (min-width: 601px) and (max-width: 1200px) {
    .model {
      transform: scale(0.8);
    }
  }
`;


export function Scene({setIsMouseVisible, setWhitelistPopup, orbitControlsRef, setIsFooterVisible, isDarkMode, setButtonPopup, buttonPopup, openWhitelistLink, orbitControlsEnabled, scroll, props}) {
  
  const { size } = useThree();
  const [scale, setScale] = useState(1);
  const [scale2, setScale2] = useState(1);
  //console.log("setButtonPopup prop:", setButtonPopup);
  useEffect(() => {
    const updateScale = () => {
      const width = size.width;
      let newScale = 1;

      if (width <= 600) {
        newScale = 0.4; // Adjust the scale value as needed for smaller screens
      } else if (width > 600 && width <= 1200) {
        newScale = 0.71428571; // Adjust the scale value as needed for medium-sized screens
      }
      setScale2(newScale)
      setScale(newScale);
    };

    updateScale();

    window.addEventListener('resize', updateScale);

    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, [size?.width]);
  //console.log("setButtonPopup prop:", setButtonPopup);


  return (
    <>
          {isDarkMode ? (
            <>
            {
              /*
            <DiscoBallAnimation scale={scale2 * 0.4} position={position} />
            */}
            </>
          ) : (
           <> 
           {/*
           <SunAnimation  scale={scale2 * 0.4}  position={position} />
           */}
           </>
          )}
         
          {setButtonPopup && (
              <W3oHomeCloset
              orbitControlsEnabled={orbitControlsEnabled}
              setWhitelistPopup={setWhitelistPopup}
              setIsMouseVisible={setIsMouseVisible}
                  orbitControlsRef={orbitControlsRef}
                  setIsFooterVisible={setIsFooterVisible}
                  openWhitelistLink={openWhitelistLink} // Pass the openWhitelistLink function as a prop
                  setButtonPopup={setButtonPopup}
                  buttonPopup={buttonPopup}
                  scale={scale}
                  props={props}
              />
              
            )}
      
  </>
  );
}







export default function ClosetModel({whitelistPopup, setWhitelistPopup, isMouseVisible, setIsMouseVisible, orbitControlsRef, setIsFooterVisible, isDarkMode, orbitControlsEnabled, setButtonPopup, buttonPopup, started, toggleOrbitControls, modelSwitch, setModelSwitch, props }) {
  const { camera, gl } = useThree();
  const ref = useRef();
  const controlsRef = useRef();
  const [perfSucks, degrade] = useState(false);
  const [position, setPosition] = useState([0, -1.5, 0]);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width <= 600) {
        setPosition([0, -0.25, 0]); // Adjust the position value as needed for smaller screens
      } else {
        setPosition([0, -0.85, 0]); // Default position value for larger screens
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openWhitelistLink = () => {
    setButtonPopup(true);
  };

  const tempCameraProps = useRef({
    position: new THREE.Vector3(20, 0.9, 20),
    fov: 26,
  });
  
  // Update the camera properties when modelSwitch changes
  useEffect(() => {
    if (modelSwitch) {
      // Calculate and store the current camera position
      tempCameraProps.current.position.copy(camera.position);
      tempCameraProps.current.fov = camera.fov;
  
      // Set the new camera properties
      camera.position.set(20, 0.9, 20);
      camera.fov = 26;
      camera.updateProjectionMatrix();
    } else {
      // Restore the camera properties from the temporary constant
      camera.position.copy(tempCameraProps.current.position);
      camera.fov = tempCameraProps.current.fov;
      camera.updateProjectionMatrix();
    }
  }, [camera, modelSwitch]);


  const controls = useThree(s => s.controls);
useEffect(() => {
  if (controls) {
    const handler = (event) => {
      /* do stuff */
     // console.log(isMouseVisible);
      setIsMouseVisible(false);
      //console.log(isMouseVisible);
    };
    
    controls.addEventListener('start', handler);
    return () => controls.removeEventListener('start', handler);
  }
});
  return (
    <group>

      {orbitControlsEnabled && <OrbitControls  makeDefault  args={[camera, gl.domElement]} enableZoom  enableRotate minDistance={5} maxDistance={25} zoomSpeed={0.6} panSpeed={0.5} rotateSpeed={0.4} />}
      <PerformanceMonitor onDecline={() => degrade(true)} />
      <color attach="background" args={['#000']} />
      {/*<TextThreeD />*/}
      <group position={position} rotation={[0, -0.75, 0]}>
      <pointLight position={[-0.5, -1.75, 0]} intensity={orbitControlsEnabled ? 3.5 : 1.5} />
        <Sparkles size={2} color={isDarkMode ? '#fff' : '#cc5500'} scale={[10, 10, 10]} />
        <Scene
        setWhitelistPopup={setWhitelistPopup}
        setIsMouseVisible={setIsMouseVisible}
            orbitControlsRef={orbitControlsRef}
        setIsFooterVisible={setIsFooterVisible}
          openWhitelistLink={openWhitelistLink}
          setButtonPopup={setButtonPopup}
          buttonPopup={buttonPopup}
          orbitControlsEnabled={orbitControlsEnabled}
          isDarkMode={isDarkMode}
          props={props}
        />
        <mesh scale={[0.95, 1, 0.95]} material={innerMaterial} />
        <mesh material={innerMaterial} />
 
    
      </group>
      
      {window.innerWidth <= 600 ? <OrbitControls args={[camera, gl.domElement]} enableZoom enablePan enableRotate zoomSpeed={0.6} panSpeed={0.5} rotateSpeed={0.4} /> : <></>}
  
    
      
    </group>
  );
}
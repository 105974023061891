//import emailjs from 'emailjs-com';
import React, { useRef, useState } from 'react';
//import "./NFT.scss";
import {
  NFTFormContainerIntro,
  NFTFormIntro,
  initialState
} from "./NFTElements";

import coinbaseWalletModule from '@web3-onboard/coinbase';
import Onboard from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';
import walletConnectModule from '@web3-onboard/walletconnect';
import Web3 from 'web3';
import { KaveBtn } from '../RedeemPageElements';



const MAINNET_RPC_URL = "https://goerli.infura.io/v3/7c9d1c18f6244605b8d57b8dd612aa7e";
const wcInitOptions = {
  /**
   * Project ID associated with [WalletConnect account](https://cloud.walletconnect.com)
   */
  projectId: 'abc123...',
  /**
   * Chains required to be supported by all wallets connecting to your DApp
   */
  requiredChains: [1],
  /**
   * Chains required to be supported by all wallets connecting to your DApp
   */
  optionalChains: [42161, 8453, 10, 137, 56],
  /**
   * Defaults to `appMetadata.explore` that is supplied to the web3-onboard init
   * Strongly recommended to provide atleast one URL as it is required by some wallets (i.e. MetaMask)
   * To connect with WalletConnect
   */
  dappUrl: 'http://YourAwesomeDapp.com'
}
const injected = injectedModule();
const walletConnect = walletConnectModule(wcInitOptions)
const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: false })


const onboard = Onboard({
  wallets: [
      injected,
      walletConnect,
      coinbaseWalletSdk
  ],
  chains: [
    {
      id: '0x5',
      token: 'gETH',
      label: 'Goerli Testnet',
      rpcUrl: MAINNET_RPC_URL
    }
  ]
});



function IntroPage({showFAQ, setShowFAQ, isDarkMode}) {

//Wallet Connect Details
const [walletAddress, setWalletAddress] = useState("");
console.log("active");

async function loadWeb3() {
 // console.log(loadWeb3);
  try {
      const wallets = await onboard.connectWallet();
      let web3 = window.web3 = new Web3(wallets[0].provider);
      const account = wallets[0].accounts[0].address;
      console.log("account", account)
      const msgToSign = web3.utils.sha3("Wallet Verification Message for Web3 Outfitters.");
      const signature = await web3.eth.personal.sign(msgToSign, account);
      const signingAddress = web3.eth.accounts.recover(msgToSign, 
      signature);
      console.log("signingAddress", signingAddress)
      if (account.toLowerCase() === signingAddress.toLowerCase()) {
          window.account = account;
          setWalletAddress(account);
      }
      return [(account.toLowerCase() === signingAddress.toLowerCase()), account];
  }
  catch (e) {
      console.log(e);
      return [false, ""];
  }
}

var user = {
name: walletAddress,
avatar: 'https://www.w3schools.com/howto/img_avatar.png',
bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
email: 'test@example.com'
}
// const user = useContext(UserContext);
console.log(user);

// const user = useContext(UserContext);
// console.log(user);
    
 
// This function validates emails

const initialValues = { name: "", email: "", message: "", error: "" };
const [formValues, setFormValues] = useState(initialValues);
const [state, setState] = useState(initialState);
const [error, setError] = useState('');
const [formErrors, setFormErrors] = useState({});
const [isSubmit, setIsSubmit] = useState(false);
const [name, setName] = useState("");

const handleInput = (e) => {
  const { name, value } = e.target;
 
  
  setState({ ...state, [name]: value });
};


///FAQ Functions
const [activeIndex, setActiveIndex] = useState(null);


  const toggleFAQ = (e) => {
    e.preventDefault(); 
    console.log("FAQ", showFAQ);
    setShowFAQ(!showFAQ);
  };


const form = useRef();

/*

    const sendEmail = (e) => {
   
      e.preventDefault();
      setFormErrors(validate(state));
      if (Object.keys(validate(state)). length === 0) {
  
     
      emailjs.sendForm('gmail', '', form.current, '')
        .then((result) => {
            console.log(result.text);
            
       
        }, (error) => {
            console.log(error.text);
         });
        
        }
      
        setState("")
        setIsSubmit(true);
        e.target.reset();
      }
      */

      const validate = (v) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!v.email) {
          errors.email = "Refresh to send another message";
        } else if (!regex.test(v.email)) {
          errors.email = "Refresh to send another message";
        }
        if (!v.name) {
          errors.name = "Name field can't be empty";
        }
    
        if (!v.message) {
          errors.message = "Message field can't be empty";
        }
    
        return errors;
      };
  
    return (
      <>
        <NFTFormContainerIntro isDarkMode={isDarkMode} id = {'/carrierForm'}>
        
        <div className='bit5'/>
          <NFTFormIntro isDarkMode={isDarkMode} ref = {form}  /*onSubmit={sendEmail}*/>
            <h2>Available Wallets</h2>
                 
      <KaveBtn  isDarkMode={isDarkMode} onClick={loadWeb3} type="button">Redeem</KaveBtn>
      <KaveBtn  isDarkMode={isDarkMode}  type="button">Verified Collection</KaveBtn>
      <KaveBtn  isDarkMode={isDarkMode}  type="button" onClick={toggleFAQ}>FAQs</KaveBtn>
          </NFTFormIntro>
      

        </NFTFormContainerIntro>
     
      </>
    )
  }
  
  export default IntroPage;

import styled, { createGlobalStyle, css } from 'styled-components';

const BoldText = css`
  font-weight: 800; // Or the maximum weight your font supports
`;

export const StyledParagraph = styled.p`
  ${BoldText}
`;

export const StyledHeading = styled.h2`
  ${BoldText}
`;

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  body {
    /*font-family: Arial, Helvetica, sans-serif;*/
    background: linear-gradient(to bottom, #f05053, #e1eec3);
    height: 100%;
    margin: 0;
    color: #555;
  }
`;



export const sharedStyles = css`
  background-color: #eee;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #ddd;

  /*
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
  */
`;
export const NFTFormContainerIntro = styled.div`
  position: absolute;
  display: flex;
 // color: #fff;
  justify-content: center;
  //color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  align-items: center;
  height: 60vh;
  padding: 0 20px;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 768px) {
    transform: translate(-50%, -55%);

}
`;

export const NFTFormIntro = styled.form`
  width: 100%;
  color: #fff;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  max-width: 700px;
  padding: 20px;
  backdrop-filter: blur(50px);
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  @media screen and (max-width: 768px) {
    min-width: 350px;
    transform:scale(0.85);

}
`;
export const NFTFormContainer = styled.div`
position: absolute;
  display: flex;

  //color: #fff;
 
  justify-content: center;
  align-items: center;
  height: 80vh;
  padding: 0 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const NFTForm = styled.form`
  background-color: ${({ isDarkMode }) => (isDarkMode ? 'rgb(49,44,40)' : '#fff')};
  width: 100%;
  //color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  //background: ;
//background: linear-gradient(90deg, rgba(49,44,40,1) 0%, rgba(81,76,71,1) 35%, rgba(255,255,255,1) 100%);
  max-width: 700px;
  padding: 40px;
  backdrop-filter: blur(30px);
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
`;

export const NFTFormInput = styled.input`
  display: block;
  width: 100%;
  color: #000;

  ${sharedStyles}
`;

export const NFTFormTextArea = styled.input`
  background-color: #eee;
  width: 100%;
  color: #000;
  min-height: 100px;
  resize: none;
  ${sharedStyles}
`;
export const NFTFormButton = styled.button`
  display: block;
  background-color: #f7797d;
  color: #fff;
  font-size: 0.75rem;
  border: 0;
  border-radius: 5px;
  height: 35px;
  padding: 0 20px;
  cursor: pointer;
  box-sizing: border-box;
  &:not([disabled]):focus,
  &:not([disabled]):hover {
    box-shadow: 0 0 0.25rem rgba(0, 255, 1, 0.5),
      -0.125rem -0.125rem 1rem rgba(33, 130, 7, 0.5),
      0.125rem 0.125rem 1rem rgba(31, 82, 16, 0.5);
  }
`;

export const NFTFormFieldSet = styled.fieldset`
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #fff;
  padding: 20px;
  line-height: 25px;
  margin: 20px 0;
  legend {
    padding: 0 20px;
    color: #fff;
  }
  label {
    padding-right: 20px;
    color: #fff;
  }
  input {
    margin-right: 10px;
    color: #fff;
  }
`;

export const NFTFormError = styled.div`
  color: red;
  font-weight: 800;
  margin: 0 0 40px 0;
`;

export const initialState = {
  name: '',
  email: '',
  message: '',
  service: '',
  website: ''
};
export const ContactImg = styled.div`
    top: 60%;
    left: 50%;
    height: 500px;
    width: 600px;
    margin-right: -50%;
    transform: translate(-225%, 80%);
    position: absolute;
    
    border-top: 3px solid transparent;
    border-radius: 25px;
  // background-image: url('../../images/cthulu.gif');
    @media screen and (max-width: 850px) {
    align-items: center;
        display:block;
    position: absolute;
    left: 150%;


}
`;



export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HalfWidthInput = styled(NFTFormInput)`
  width: 48%; // Adjust based on padding/margin
`;


//Internal Button

export const FuturisticButton = styled.button`
  cursor: pointer;
  position: relative;
  border: solid 1px ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 0.3)' : 'rgba(0, 0, 0, 0.3)'};
  background: none;
  color: ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 0.5)' : 'rgba(0, 0, 0, 0.5)'};
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1.8px;
  width: 300px;
  height: 40px;
  padding: 0;
  transition: all 0.25s ease;
  outline: none;

  &:before, &:after {
    content: "";
    position: absolute;
    width: 60px;
    height: 1px;
    background: ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 1)' : 'rgba(0, 0, 0, 1)'};
    transition: all 0.25s ease;
  }

  &:before {
    top: 0;
    left: 10%;
    transform: translateY(-1px);
  }

  &:after {
    bottom: 0;
    right: 10%;
    transform: translateY(1px);
  }

  &:hover {
    box-shadow: 1px 1px 8px ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 0.3)' : 'rgba(0, 0, 0, 0.3)'};
    color: ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 1)' : 'rgba(0, 0, 0, 1)'};
    text-shadow: 0 0 8px ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 0.4)' : 'rgba(0, 0, 0, 0.4)'};

    &:before {
      left: 0;
      width: 20px;
    }

    &:after {
      right: 0;
      width: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    min-width: 320px;
    height: 40px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; // Adjust the space between buttons as needed
  flex-wrap: wrap; // Allows buttons to wrap to next line if space is constrained

  @media screen and (max-width: 768px) {
    flex-direction: column; // Stacks buttons vertically on smaller screens
  }
`;
import React, { useState } from 'react';
import faqData from './faqData'; // Adjust the import path as needed

import {
  AccordionContainer,
  AccordionContent,
  AccordionTitle,
  AccordionWrapper,
  Button,
  Container,
  ExitIcon, ExitIconWrap, PExit,
  PopUpContainer
} from './FAQElements';

const FAQAccordion = ({isDarkMode, setShowFAQ, showFAQ}) => {
  const [expandedItem, setExpandedItem] = useState(null);

  const toggleAccordion = (itemId) => {
    setExpandedItem(prevItem => prevItem === itemId ? null : itemId);
  };

  return (
    <PopUpContainer isDarkMode={isDarkMode} initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}>
      <PExit className='close-btn' onClick={() => setShowFAQ(false)}>
        <ExitIconWrap
          whileHover={{ rotate: 180, transition: { duration: 0.25 } }}
          whileTap={{ rotate: 180, transition: { duration: 0.25 } }}
        >
          <ExitIcon whileHover={{ color: isDarkMode ? '#000' : '#fff', backgroundColor: isDarkMode ? '#fff' : '#000' }} />
        </ExitIconWrap>
      </PExit>

      <Container isDarkMode={isDarkMode }>
        <h2>Frequently Asked Questions</h2>
        <AccordionContainer  isDarkMode={isDarkMode }>
          {faqData.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              <h3>{section.header}</h3>
              {section.questions.map((question, questionIndex) => (
                <AccordionWrapper  isDarkMode={isDarkMode } key={`section-${sectionIndex}-question-${questionIndex}`}>
                  <Button  isDarkMode={isDarkMode }
                    expanded={expandedItem === `accordion-${sectionIndex}-${questionIndex}`}
                    aria-expanded={expandedItem === `accordion-${sectionIndex}-${questionIndex}`}
                    onClick={() => toggleAccordion(`accordion-${sectionIndex}-${questionIndex}`)}
                  >
                    <AccordionTitle>{question.question}</AccordionTitle>
                  </Button>
                  <AccordionContent  isDarkMode={isDarkMode } expanded={expandedItem === `accordion-${sectionIndex}-${questionIndex}`}>
                    <p>{question.answer}</p>
                    {question.bullets && question.bullets.map((bullet, bulletIndex) => (
                      <li key={`bullet-${bulletIndex}`}>{bullet}</li>
                    ))}
                  </AccordionContent>
                </AccordionWrapper>
              ))}
            </div>
          ))}
        </AccordionContainer>
      </Container>
    </PopUpContainer>
  );
};

export default FAQAccordion;

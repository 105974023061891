//import emailjs from 'emailjs-com';
import React, { useRef, useState } from 'react';
//import "./NFT.scss";
import {
  NFTForm,
  NFTFormContainer,
  StyledHeading,
  initialState
} from "./NFTElements";





function ThankYou({isDarkMode}) {


    
 
// This function validates emails

const initialValues = { name: "", email: "", message: "", error: "" };
const [formValues, setFormValues] = useState(initialValues);
const [state, setState] = useState(initialState);
const [error, setError] = useState('');
const [formErrors, setFormErrors] = useState({});
const [isSubmit, setIsSubmit] = useState(false);
const [name, setName] = useState("");

const handleInput = (e) => {
  const { name, value } = e.target;
 
  
  setState({ ...state, [name]: value });
};

const form = useRef();

/*

    const sendEmail = (e) => {
   
      e.preventDefault();
      setFormErrors(validate(state));
      if (Object.keys(validate(state)).length === 0) {
  
     
      emailjs.sendForm('gmail', '', form.current, '')
        .then((result) => {
            console.log(result.text);
            
       
        }, (error) => {
            console.log(error.text);
         });
        
        }
      
        setState("")
        setIsSubmit(true);
        e.target.reset();
      }
      */

      const validate = (v) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!v.email) {
          errors.email = "Refresh to send another message";
        } else if (!regex.test(v.email)) {
          errors.email = "Refresh to send another message";
        }
        if (!v.name) {
          errors.name = "Name field can't be empty";
        }
    
        if (!v.message) {
          errors.message = "Message field can't be empty";
        }
    
        return errors;
      };
  
    return (
      <>
        <NFTFormContainer isDarkMode={isDarkMode} id = {'/carrierForm'}>
        
 
          <NFTForm  isDarkMode={isDarkMode}  ref = {form}  /*onSubmit={sendEmail}*/>
            <StyledHeading isDarkMode={isDarkMode}>Thank You</StyledHeading>
          </NFTForm>

        </NFTFormContainer>
      </>
    )
  }
  
  export default ThankYou;